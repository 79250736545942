// import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
// import { MdLineWeight,MdViewAgenda } from "react-icons/md";
import {FaBars,FaPlay,FaEnvelope} from "react-icons/fa";
import Popup from '../Popup'
import {MenuButton} from './menuBtn'
import Keyboard from '../Keyboard'
import pianoBearBadge from '../../images/headerBadgeSmall.png'

// import {FaLink,FaThumbsUp,FaAlignJustify,FaQuestion,FaUserGraduate,FaHashtag,FaList,FaMoon,FaUserAlt,FaStream,FaHeart,FaClipboard} from "react-icons/fa";
// import Badge from './imagePianoBear'

import './style.css'

const playButtonPressed = () => {
  document.getElementById("playAudio").play(); 
  setTimeout(()=>{
  Popup.alert("You pressed the play button");  // 🙌🙌🙌👯‍🎉🌈🦄
  Popup.alert("🙌🙌🙌")
  Popup.alert("Press OK if you think Piano Bear is awesome")
  },200)
}

const menuButtonPressed = () => {
  Popup.create({
    title: '',
    content: 'In theory it should open a menu',
    className: 'alert',
    buttons: {
        right: [{text:'OK',action: ()=>{Popup.close()}}]
    }
  }, true);
  Popup.create({
    title: '',
    content: 'You pressed the menu button',
    className: 'alert',
    buttons: {
        right: [{text:'OK',action: ()=>{Popup.close()}}]
    }
  }, true);
  
Popup.create({
  content: <div>Reality: I didn't have time to make a menu ...<br/><br/>Sorry, you have to read the entire page</div>,
  className: 'alert',
  buttons: {
      right: [{
        text: 'OK',
        action: function () {
            Popup.close();
            setTimeout(()=>{  Popup.alert(<div>Do you enjoy pressing OK buttons?<br/><br/>Press OK to confirm</div>);},2000); //Popup.alert(<div>OK?</div>);Popup.alert(<div>Are you really OK?<br/><br/>Press OK if you are OK with pressing OK buttons</div>); 
        }
        }]
  }
}, false);

}

const HeaderStart = ({ siteTitle }) => (

  <header className="start">
    {/* <Keyboard/> */}

    <MenuButton onClick={menuButtonPressed}/>
    <button onClick={()=>{playButtonPressed()}} style={{top: 0, right: 0,height: '40px', width: '40px', opacity: 0.7,}}><FaPlay/></button>
    {/* <button onClick={()=>{}} style={{position: 'absolute',top: '40px', left: 0,height: '40px', width: '40px',zIndex:9}}><FaEnvelope/></button> */}
    {/* <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
      }}
    >
      <h1 style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          {siteTitle}
        </Link>
      </h1>
    </div> */}
    
  </header>
)

const HeaderPage = ({children}) => (
  <header style={{top: '-5px'}}>
    <Keyboard/>
    {children}
    <img src={pianoBearBadge}/>
    {/* <MenuButton onClick={menuButtonPressed}/>
    <button onClick={()=>{playButtonPressed()}} style={{top: 0, right: 0,height: '40px', width: '40px', opacity: 0.7,}}><FaPlay/></button>
     */}
  </header>
)

HeaderStart.propTypes = {
  siteTitle: PropTypes.string,
}

HeaderStart.defaultProps = {
  siteTitle: ``,
}


export {HeaderPage,HeaderStart}
