import PropTypes from "prop-types"
import React from "react"
import {Link} from "gatsby"
import {FaBars,FaArrowAltCircleLeft} from "react-icons/fa";



const MenuButton = ({onClick}) => <button onClick={()=>{onClick()}} style={{left: 0, top: 0,height: '40px', width: '40px'}}><FaBars/></button>

const GoBackButton = () => {
    const randomRotation = Math.random()*4-2;
return <button onClick={()=>{try {window.history.back()} catch (e) {} }} style={{background: 'rgba(0,0,0,0.5)', color: 'white', left: 0, top: 0,height: '40px', background: 'transparent', border: 0, fontSize: 14, textShadow: '0px 0px 5px black'}}><FaArrowAltCircleLeft style={{verticalAlign: 'middle', background: 'black'}}/> <span style={{background: 'black', padding: '0px 2px', transform: 'rotateZ('+ randomRotation + 'deg)',display:'inline-block'}}>PIANO BEAR'S HOME</span></button>
}

const MenuBackButton = ({to,text}) => {
    const randomRotation = Math.random()*4-2;
return <Link to={to} style={{background: 'rgba(0,0,0,0.5)', color: 'white', left: 10, top: 15,height: '40px', background: 'transparent', border: 0, fontSize: 14, textShadow: '0px 0px 5px black', position:'absolute'}}><FaArrowAltCircleLeft style={{verticalAlign: 'middle', background: 'black'}}/> <span style={{background: 'black', padding: '0px 2px', transform: 'rotateZ('+ randomRotation + 'deg)',display:'inline-block'}}>{text}</span></Link>
}

const showMenu = () => {
    //TODO: implement menu
    }

const DefaultMenuButton = MenuButton({onClick:showMenu})

const Menu = ({children}) => <div style={{marginLeft: '-20px'}}>{children}</div>



export default DefaultMenuButton;

export {MenuButton,GoBackButton,Menu,MenuBackButton};