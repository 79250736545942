import React from "react"

import keys from '../images/keys1.svg'

const Keyboard = ({bottom}) => {
    // const maxKeys = 12;
    // let keys = []
    // for (var i = 0; i < maxKeys; i++) {
    //     let key;
    //     const keyInOctave = i % 12;
    //     if (keyInOctave == 1 || keyInOctave == 3 || keyInOctave == 6 || keyInOctave == 8 || keyInOctave == 10)
    //         key = <div key={i} style={{height: '80%', width: '8%', position: 'absolute', bottom: 0, left: 0}}></div>
    //     keys.push(key)
    // }

    return (
        <div id="keyboard" style={{backgroundImage: 'url(' + keys + ')', transform: bottom ? 'rotateZ(180deg)' : '', marginBottom: bottom ? '-25px' : 0 }}>
            {/* <img src={keys}/> */}
        </div>
    )
}

export default Keyboard;